import React from 'react';
import { Actions } from './actions';
import { ColumnsType } from 'antd/es/table';

import { Offer, OfferPaymentModel } from 'types/offer';

export const columns: ColumnsType<Offer> = [
  {
    title: 'Domain',
    dataIndex: 'domain',
    key: 'domain',
    ellipsis: true,
  },
  {
    title: 'Payment Model',
    dataIndex: 'paymentModel',
    key: 'paymentModel',
    ellipsis: true,
  },
  {
    title: 'Goal',
    dataIndex: 'goal',
    key: 'goal',
    ellipsis: true,
    render: (v, record) => `${v} ${record.paymentModel === OfferPaymentModel.REVSHARE ? '%' : '$'}`,
  },
  {
    title: 'Geo',
    dataIndex: 'geo',
    key: 'geo',
    ellipsis: true,
  },
  {
    title: 'Min Dep',
    dataIndex: 'minDep',
    key: 'minDep',
    ellipsis: true,
    render: value => `${value}$`,
  },
  {
    title: 'Action',
    key: 'action',
    align: 'center',
    ellipsis: true,
    render: value => <Actions id={value.id} />,
  },
];
