/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable indent */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { useState } from 'react';

import classNames from 'classnames';
import { ExpandedTable } from 'components/Table/expandedTable';
import { DeleteCampaignAction } from '../utils/trafficActions';
import { prepareExpandedColumns } from '../utils/tableConfig';
import styles from '../Home/styles.module.scss';
import { expandedSummary } from '../utils/summary';
import { OfferPaymentModel } from 'types/offer';
import { copy } from 'utils/copy';

export const TitleElement = ({ title, value, isCopy, warn }) => {
  if ((value === undefined || value === null) && !warn) return null;

  return (
    <div className={classNames(styles.titleElement)}>
      <div className={styles.titleElementItem}>{title}</div>
      <div
        className={classNames(styles.titleElementItem, isCopy && styles.copy, warn && styles.warn)}
        onClick={() => isCopy && copy(value)}
      >
        {value}
      </div>
    </div>
  );
};

export const ExpandedElement = ({
  name,
  geo,
  landing,
  generatedUrlToSite,
  generatedUrlToLanding,
  generatedUrlToRedirectLanding,
  numericId,
  traffics,
  kapa,
  cpa,
  approved,
  id,
  offer,
  action,
  promocodes,
  fetchTraffic,
  renderCell,
}) => {
  const expandedColumns = prepareExpandedColumns();
  const [isActive, setActive] = useState(false);
  let newCPA = 'Needs to set cpa';

  if (cpa) {
    newCPA = cpa;
  }

  if (offer?.paymentModel === OfferPaymentModel.REVSHARE) {
    newCPA = 'RevShare';
  }

  return (
    <div key={id} className={styles.expandedContainer}>
      <div className={styles.expandedHeader}>
        <h2>{numericId}</h2>
        <button
          className={classNames(
            'ant-table-row-expand-icon',
            isActive ? 'ant-table-row-expand-icon-expanded' : 'ant-table-row-expand-icon-collapsed',
          )}
          onClick={() => setActive(!isActive)}
        />

        <div className={styles.titleTable}>
          <TitleElement title="Name" value={name} />
          <TitleElement title="Geo" value={geo} />

          {action && (
            <>
              {!offer ? (
                <TitleElement title="Offer" value={'Offer was removed'} />
              ) : (
                <>
                  <TitleElement title="Payment Model" value={offer?.paymentModel || 'Offer  removed'} />
                  <TitleElement title="Goal" value={offer?.goal || 'Offer removed'} />
                </>
              )}
            </>
          )}

          <TitleElement title="Kapa" value={kapa} />
          <TitleElement
            title="CPA"
            value={newCPA}
            warn={
              (cpa && offer?.paymentModel === OfferPaymentModel.CPA) ||
              (!cpa && offer?.paymentModel === OfferPaymentModel.REVSHARE) ||
              (cpa && !offer)
                ? false
                : true
            }
          />

          <TitleElement title="Landing" value={landing} />

          <TitleElement title="Casino Link" value={generatedUrlToSite} isCopy />
          <TitleElement title="Landing Link" value={generatedUrlToLanding} isCopy />
          <TitleElement title="Redirect Link" value={generatedUrlToRedirectLanding} isCopy />

          {promocodes && promocodes.length > 0 && (
            <TitleElement isCopy title="Promocodes" value={promocodes.toString().replaceAll(',', ', ')} />
          )}

          <div className={styles.actions}>
            <DeleteCampaignAction approved={approved} id={id} fetchTraffic={fetchTraffic} />
          </div>
        </div>
      </div>

      {isActive && (
        <ExpandedTable
          meta={{
            currentPage: 1,
            total: traffics?.length,
            totalPages: traffics?.length / 10,
          }}
          data={traffics}
          columns={expandedColumns}
          hideSizeChanger={true}
          renderCell={renderCell}
          summary={arr => expandedSummary(arr)}
        />
      )}
    </div>
  );
};
