import React, { useEffect, useState } from 'react';
import { Popover } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { userStatistic } from 'api/user';

interface UserData {
  depositsSum: number;
  bonusSum: number;
  wagerSum: number;
}

interface UserProps {
  userId: string;
}
const DataContent = ({ userId }: UserProps) => {
  const [data, setData] = useState<UserData>();
  const [loading, setLoading] = useState<boolean>(false);

  const fetchUserStatistic = async () => {
    setLoading(true);
    const data = await userStatistic(userId);

    setData(data);

    setLoading(false);
  };

  useEffect(() => {
    fetchUserStatistic();
  }, [userId]);

  return (
    <div>
      {loading ? (
        'Loading...'
      ) : (
        <div>
          Amount: {Number(data?.depositsSum) - Number(data?.bonusSum)}
          <br />
          Deposits: {data?.depositsSum}
          <br />
          Bonuses: {data?.bonusSum}
          <br />
          Wager Sum: {data?.wagerSum}
        </div>
      )}
    </div>
  );
};

export const UserData = ({ userId }: UserProps) => (
  <Popover content={<DataContent userId={userId} />} title="User Statistic">
    {' '}
    <UserOutlined />
  </Popover>
);
