import {
  createApi,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { API_ROUTES } from 'api/routes';
import local from 'hooks/local';
import { baseQueryWithReauth } from 'store/interceptor';
import {
  TLoginWithGoogle,
  TSignIn,
  TUser,
} from 'types/auth';

const storage = local;
const storageToken = storage.local<string>('token', { stringify: false });

type TLoginResponse = TLoginWithGoogle | TUser;

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: [ 'Auth' ],
  endpoints: (builder) => ({
    login: builder.mutation<TLoginResponse, TSignIn>({
      async queryFn (_arg, _queryApi, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: API_ROUTES.SIGN_IN,
          method: 'POST',
          body: {
            ..._arg,
          },
        });

        const signInResult = result.data
          ? { data: result.data as TLoginResponse }
          : { error: result.error as FetchBaseQueryError };

        if (!signInResult.error && signInResult.data && 'token' in signInResult.data) {
          storageToken.set(signInResult.data.token);
        }

        return signInResult;
      },
    }),
    logout: builder.mutation<boolean, string>({
      async queryFn (_arg, _queryApi, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: API_ROUTES.LOGOUT,
          method: 'POST',
          body: {
            currentToken: _arg,
          },
        });

        const logoutResult = result.data
          ? { data: result.data as boolean }
          : { error: result.error as FetchBaseQueryError };

        if (!logoutResult.error && logoutResult.data) {
          storageToken.remove();
        }

        return logoutResult;
      },
    }),
  }),
});

export const { useLoginMutation } = authApi;
