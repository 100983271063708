/* eslint-disable function-paren-newline */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-bracket-spacing */
import React, { useEffect, useState } from 'react';
import { Button, Form, Input, InputNumber, message, Select, Tabs } from 'antd';
import { editCampaign, getCampaign, getOffers } from 'api/traffic';
import { useGetParam } from 'utils/useGetParam';
import { promocodesApi } from 'store/services';

type Props = {
  closeHandler: (onOk: boolean) => void;
  setPartner?: boolean;
};

export const EditCampaign: React.FC<Props> = ({ closeHandler, setPartner }) => {
  const messageKey = 'messageKey-1';

  const [offers, setOffers] = useState<any>();

  const [fields] = Form.useForm();
  const [_, setIsLoading] = useState<boolean>(false);

  const campaignId = useGetParam('campaignId');

  const { data: promocodes } = promocodesApi.useGetAllPromocodesWithoutPaginationQuery('');

  const newPromocodes = promocodes?.map(({ id, name }: { id: string; name: string }) => ({
    label: name,
    value: id,
  }));

  const newOffers = offers?.map(
    ({ id, paymentModel, geo, goal }: { id: string; paymentModel: string; geo: string; goal: string }) => ({
      label: `${paymentModel}/ ${geo}/ ${goal}`,
      value: id,
    }),
  );

  const onFinish = async () => {
    try {
      setIsLoading(true);
      message.open({
        key: messageKey,
        type: 'loading',
        content: 'Sending data...',
      });

      await editCampaign({ ...fields.getFieldsValue(), campaignId });

      setIsLoading(false);
      closeHandler(true);
    } catch (e) {
      message.open({
        key: messageKey,
        type: 'error',
        content: 'Error',
        duration: 2,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onFinishChecker = async () => {
    message.error('Error in form');
  };

  useEffect(() => {
    const getCampaignById = async (campaignId: string | null) => {
      const campaign = await getCampaign(campaignId);

      const fetchOffers = await getOffers({ perPage: 30 });

      if (fetchOffers) {
        setOffers(fetchOffers);
      }

      fields.setFieldsValue({ ...campaign, offer: campaign?.offer?.id });
    };

    getCampaignById(campaignId);
  }, []);

  return (
    <Form form={fields} layout="vertical" onFinishFailed={onFinishChecker}>
      <Form.Item
        name="name"
        label="Campaign Name"
        rules={[
          {
            required: true,
            message: 'Please enter campaign name',
          },
        ]}
      >
        <Input style={{ maxWidth: '350px' }} placeholder="Enter campaign name" />
      </Form.Item>

      <Form.Item
        name="geo"
        label="Geo"
        rules={[
          {
            required: true,
            message: 'Please enter geo',
          },
        ]}
      >
        <Input style={{ maxWidth: '350px' }} placeholder="Enter GEO" />
      </Form.Item>

      <Form.Item
        name="cpa"
        label="Command CPA"
        rules={[
          {
            required: true,
            message: 'Please enter command CPA',
          },
        ]}
      >
        <InputNumber style={{ width: '350px' }} min={1} placeholder="Enter command CPA" />
      </Form.Item>

      <Form.Item
        name="kapa"
        label="KAPA"
        rules={[
          {
            required: true,
            message: 'Please enter command KAPA',
          },
        ]}
      >
        <InputNumber style={{ width: '350px' }} min={1} placeholder="Enter command KAPA" />
      </Form.Item>

      <Form.Item name="offer" label="Offer">
        <Select options={newOffers} style={{ width: '350px' }} placeholder="Select Offer" />
      </Form.Item>

      <Form.Item name="promocodes" label="Promocode">
        <Select mode="multiple" options={newPromocodes} style={{ width: '350px' }} placeholder="Select Promocode" />
      </Form.Item>

      <Button onClick={() => onFinish()}>Submit</Button>
    </Form>
  );
};
