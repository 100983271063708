import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { API_ROUTES } from 'api/routes';

import { baseQueryWithReauth } from '../interceptor';

export const paymentsApi = createApi({
  reducerPath: 'paymnetsApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Payments'],
  endpoints: builder => ({
    changeStatus: builder.mutation<any, any>({
      query: params => ({
        url: `${API_ROUTES.CHANGE_PAYMENT_STATUS}`,
        body: params,
        method: 'POST',
      }),
      invalidatesTags: ['Payments'],
    }),

    getPayments: builder.query<any, any>({
      query: params => ({
        url: API_ROUTES.GET_ALL_PAYMENTS,
        params,
      }),
      providesTags: ['Payments'],
    }),

    getCommandsWithoutPartner: builder.query<any, any>({
      query: params => ({
        url: API_ROUTES.GET_COMMAND_WITHOUT_PARTNER,
        params,
      }),
      providesTags: ['Payments'],
    }),
  }),
});

export const { useChangeStatusMutation, useGetPaymentsQuery, useGetCommandsWithoutPartnerQuery } = paymentsApi;
