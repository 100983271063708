import React from 'react';
import { Button, Space } from 'antd';

import { IUser } from 'types/users';
import { confirmPartner, declinePartner } from 'api/partner';
import { Link } from 'react-router-dom';

export const BinobiPartnersActions = (record: IUser, refetch: () => void) => {

  const makeRequest = async (type: string) => {
    if (type === 'confirm') {
      await confirmPartner(record.id);
    }

    if (type === 'decline') {
      await declinePartner(record.id);
    }

    refetch();
  };

  return (
    <Space>
      {(record?.declined || record?.isPartnerConfirmed) && (
        <Link to={`edit/${record?.id}`}>
          <Button type="primary">Edit</Button>
        </Link>
      )}

      {!record?.declined && !record?.isPartnerConfirmed && (
        <>
          <Button onClick={() => makeRequest('confirm')} type={'primary'} style={{ backgroundColor: '#5fad13' }}>
            Confirm
          </Button>

          <Button onClick={() => makeRequest('decline')} danger type={'primary'}>
            Decline
          </Button>
        </>
      )}
    </Space>
  );
};
