import React from 'react';
import { IPropsSVG } from 'types';

export const LogoType: React.FC<IPropsSVG> = ({ 
  width = 74,
  height = 16, 
  fill = {
    primary: '#FCD535',
  },
  viewBox = '0 0 74 16',
  className,
  style,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fill.primary}
      style={style}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.23235 2.45702H6.68509C9.76878 2.45702 11.3106 3.54307 11.3106 5.71516C11.3106 7.14446 10.7198 8.07294 9.53807 8.50061C10.3146 8.7257 10.9055 9.10272 11.3106 9.63167C11.727 10.1606 11.9352 10.8134 11.9352 11.5899C11.9352 13.8633 10.3034 15 7.0396 15H1.23235V2.45702ZM6.95519 12.8729C7.68673 12.8729 8.22131 12.7491 8.55894 12.5015C8.89657 12.2427 9.06538 11.8375 9.06538 11.2861C9.06538 10.7346 8.89094 10.3294 8.54206 10.0706C8.20443 9.81174 7.66422 9.68232 6.92143 9.68232H4.03469V12.8729H6.95519ZM4.03469 4.58409V7.6903H6.43186C7.78239 7.6903 8.45765 7.1726 8.45765 6.13719C8.45765 5.10179 7.78239 4.58409 6.43186 4.58409H4.03469ZM15.4663 15V2.45702H18.3024V15H15.4663ZM22.4549 15V2.45702H24.6327L30.5243 9.98618V2.45702H33.3267V15H31.1489L25.2404 7.40331V15H22.4549ZM54.6294 2.45702H60.0822C63.1659 2.45702 64.7077 3.54307 64.7077 5.71516C64.7077 7.14446 64.1169 8.07294 62.9351 8.50061C63.7117 8.7257 64.3025 9.10272 64.7077 9.63167C65.1241 10.1606 65.3323 10.8134 65.3323 11.5899C65.3323 13.8633 63.7004 15 60.4367 15H54.6294V2.45702ZM60.3523 12.8729C61.0838 12.8729 61.6184 12.7491 61.956 12.5015C62.2936 12.2427 62.4625 11.8375 62.4625 11.2861C62.4625 10.7346 62.288 10.3294 61.9391 10.0706C61.6015 9.81174 61.0613 9.68232 60.3185 9.68232H57.4318V12.8729H60.3523ZM57.4318 4.58409V7.6903H59.8289C61.1795 7.6903 61.8547 7.1726 61.8547 6.13719C61.8547 5.10179 61.1795 4.58409 59.8289 4.58409H57.4318ZM68.8633 15V2.45702H71.6994V15H68.8633Z"
        fill={fill.primary}
      />
      <path
        d="M49.3227 4.47528L43.5273 1.12933C42.449 0.506757 41.0646 0.877691 40.442 1.95605L37.096 7.75141C36.4734 8.82974 36.8444 10.2142 37.9227 10.8368L43.7181 14.1827C44.7964 14.8053 46.1809 14.4343 46.8035 13.356L50.1494 7.56066C50.772 6.4823 50.401 5.09785 49.3227 4.47528ZM43.4059 4.29506C43.8525 4.55287 44.0057 5.12451 43.7478 5.57105C43.49 6.01759 42.9184 6.17076 42.4719 5.91295C42.0253 5.65513 41.8721 5.08351 42.13 4.63696C42.3878 4.19042 42.9594 4.03725 43.4059 4.29506ZM40.6036 9.14879C40.1571 8.89098 40.0039 8.31935 40.2617 7.87281C40.5196 7.42626 41.0912 7.2731 41.5377 7.53091C41.9843 7.78872 42.1374 8.36035 41.8796 8.8069C41.6218 9.25344 41.0502 9.40661 40.6036 9.14879ZM43.1557 8.46498C42.7091 8.20717 42.556 7.63554 42.8138 7.18899C43.0716 6.74245 43.6432 6.58928 44.0898 6.84709C44.5363 7.10491 44.6895 7.67654 44.4317 8.12308C44.1738 8.56962 43.6022 8.72279 43.1557 8.46498ZM43.8395 11.017C43.3929 10.7592 43.2398 10.1876 43.4976 9.74103C43.7554 9.29448 44.327 9.14131 44.7736 9.39913C45.2201 9.65694 45.3733 10.2285 45.1155 10.6751C44.8577 11.1216 44.286 11.2748 43.8395 11.017ZM45.7077 7.78116C45.2612 7.52335 45.108 6.95172 45.3658 6.50518C45.6236 6.05864 46.1953 5.90547 46.6418 6.16328C47.0883 6.42109 47.2415 6.99272 46.9837 7.43927C46.7259 7.88581 46.1543 8.03898 45.7077 7.78116Z"
        fill={fill.primary}
      />
    </svg>
  );
};

