/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Button, Form, Input, InputNumber, Select, Spin } from 'antd';
import { createOffer, getLandings, getOffer, updateOffer } from 'api/traffic';
import countries from 'utils/countries';
import { useParams } from 'react-router-dom';

interface Props {
  closeHandler: (value: boolean) => void;
}

export const OffersEdit: React.FC<Props> = ({ closeHandler }) => {
  const { id } = useParams();

  const [loading, setIsLoading] = useState(false);
  const [fields] = Form.useForm<any>();

  const onFinish = async (values: any) => {
    if (!id) await createOffer(values);

    if (id && values) await updateOffer({ id, ...values });

    closeHandler(true);
  };

    const onFinishFailed = (errorInfo: any) => {
        console.error('Failed:', errorInfo);
    };

  const [landings, setFetchedLandings] = useState<{ domain: string; id: string; imageUrl: string; language: string }[]>(
    [],
  );

  const fetchLandings = async () => {
    setIsLoading(true);
    try {
      const result = await getLandings();

      setFetchedLandings(result);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchLandings();
  }, []);

  useEffect(() => {
    const getCurrentOffer = async () => {
      setIsLoading(true);
      const landing = await getOffer(id);

      setIsLoading(false);
      fields.setFieldsValue(landing);
    };

    if (id) getCurrentOffer();
  }, [id]);

  if (loading) return <Spin />;

  return (
    <Form form={fields} layout="vertical" onFinishFailed={onFinishFailed} onFinish={onFinish}>
      <Form.Item name="domain" label="Domain">
        <Select
          options={[{ label: 'Casino', value: 'casino' }]}
          defaultActiveFirstOption
          style={{ width: '350px' }}
          placeholder="Select Domain"
        />
      </Form.Item>
      <Form.Item
        name="paymentModel"
        label="Payment Model"
        rules={[
          {
            required: true,
            message: 'Please select payment model',
          },
        ]}
      >
        <Select
          options={[
            { label: 'RevShare', value: 'revshare' },
            { label: 'CPA', value: 'cpa' },
          ]}
          style={{ width: '350px' }}
          placeholder="Select Payment Model"
        />
      </Form.Item>

      <Form.Item
        name="geo"
        label="Geo"
        rules={[
          {
            required: true,
            message: 'Please select at lest one geo',
          },
        ]}
      >
        <Select
          showSearch
          options={countries
            .sort(function (a, b) {
              if (a.value < b.value) {
                return -1;
              }
              if (a.value > b.value) {
                return 1;
              }
              return 0;
            })
            .map(({ value, label }) => ({ label: `${label}: ${value}`, value }))}
          style={{ width: '350px' }}
          placeholder="Select Geo"
        />
      </Form.Item>

      <Form.Item
        name="landings"
        label="Landings"
        rules={[
          {
            required: true,
            message: 'Please select at lest one landing',
          },
        ]}
      >
        <Select
          mode="multiple"
          showSearch
          options={landings.map(({ domain, language, id }) => ({ label: `${domain}: ${language}`, value: id }))}
          style={{ width: '350px' }}
          placeholder="Select Landing"
        />
      </Form.Item>

      <Form.Item
        name="goal"
        label="Goal"
        rules={[
          {
            required: true,
            message: 'Please input goal',
          },
        ]}
      >
        <InputNumber style={{ width: '350px' }} min={1} placeholder="Input Goal" />
      </Form.Item>

      <Form.Item
        name="minDep"
        label="Min Dep"
        rules={[
          {
            required: true,
            message: 'Please enter min dep',
          },
        ]}
      >
        <InputNumber style={{ width: '350px' }} min={1} placeholder="Enter min dep" />
      </Form.Item>

      <Button key="submit" htmlType="submit">
        Submit
      </Button>
    </Form>
  );
};
