import { Row } from 'antd';
import React from 'react';
import { children } from 'types';
import { Layout } from 'antd';

interface Props {
    children: children,
}

export const LoginLayout: React.FC<Props> = ({ children }) => {
  return (
    <Layout>
      <Row justify={'center'} align={'middle'} style={{ minHeight: '100svh' }}>
        {children}
      </Row>
    </Layout>
  );
};
