import { configureStore } from '@reduxjs/toolkit';
import { authApi, usersApi } from './services';
import { promocodesApi } from './services/promocodes';
import { paymentsApi } from './services/payments';

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [promocodesApi.reducerPath]: promocodesApi.reducer,
    [paymentsApi.reducerPath]: paymentsApi.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware()
      .concat(authApi.middleware)
      .concat(usersApi.middleware)
      .concat(promocodesApi.middleware)
      .concat(paymentsApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
