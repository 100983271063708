import React from 'react';
import { Divider, Row, Typography } from 'antd';
import { children } from 'types';

interface Props {
    label: string,
    children?: children,
}

export const Header: React.FC<Props> = ({ 
  label,
  children,
}) => {
  return (
    <>
      <Row align={'middle'} justify={'space-between'}>
        <Typography.Title 
          level={2} 
          className={'myAntd'}
        >
          {label}
        </Typography.Title>
        {children}
      </Row>
      <Divider plain />
    </>
  );
};
