import { EditCommandModal, TrafficCommandModal } from 'components/Modals/TrafficCommandModal';
import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

export const TrafficCommand = () => {
  const navigate = useNavigate();

  const closeHandler = (onOk: boolean) => {
    navigate('..');
    if (onOk) {
      navigate(0);
    }
  };

  return (
    <>
      <Outlet />
      <TrafficCommandModal open={true} closeHandler={closeHandler} />
    </>
  );
};

export const EditCommand = () => {
  const navigate = useNavigate();

  const closeHandler = (onOk: boolean) => {
    navigate('..');
    // TODO
    // remove after adding a state manager
    if (onOk) {
      navigate(0);
    }
  };

  return (
    <>
      <Outlet />
      <EditCommandModal open={true} closeHandler={closeHandler} />
    </>
  );
};

