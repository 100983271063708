/* eslint-disable function-paren-newline */
/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Tag } from 'antd';
import moment from 'moment';
import BigNumber from 'bignumber.js';
import { Link } from 'react-router-dom';
import { ColumnsType } from 'antd/es/table';

import { TUser } from 'types/auth';
import { UserData } from 'components/UserData';

const obj: { [key: string]: string } = {
  admin: 'magenta',
  user: 'geekblue',
};

const status = (value: TUser) => (
  <>
    {value.role && <Tag color={obj[value.role]}>{value.role}</Tag>}
    {value.is_verified && <Tag color="green">verified</Tag>}
  </>
);

export const columns = (isForTraffic?: boolean): ColumnsType<TUser> => [
  {
    title: '#',
    dataIndex: 'number',
    key: 'number',
    align: 'center',
    fixed: 'left',
    ellipsis: true,
  },
  {
    title: 'Username',
    dataIndex: 'username',
    key: 'username',
    fixed: 'left',
    ellipsis: true,
    render: (value, record) =>
      isForTraffic ? (
        <>
          <Link to={`/users/info/${record?.id}`}>{value}</Link>

          <UserData userId={record?.id} />
        </>
      ) : (
        <Link to={`../info/${record?.id}`}>{value}</Link>
      ),
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    ellipsis: true,
  },
  {
    title: 'First Dep.',
    dataIndex: 'firstDeposit',
    key: 'firstDeposit',
    ellipsis: true,
    render: value => new BigNumber(value).toNumber(),
  },
  {
    title: 'Wager Sum',
    dataIndex: 'totalWager',
    key: 'totalWager',
    ellipsis: true,
    render: value => new BigNumber(value).toNumber(),
  },
  {
    title: 'Last Active Session',
    dataIndex: 'sessions',
    key: 'sessions',
    ellipsis: {
      showTitle: false,
    },
    render: (value: any) => {
      if (value.length > 0) {
        const a = value.reduce((a: any, b: any) => (new Date(a?.updatedAt) > new Date(b?.updatedAt) ? a : b));

        const fulDate = new Date(a?.updatedAt)?.toISOString()?.split('T');

        const date = fulDate[0];
        const time = fulDate[1]?.slice(0, 8);

        return `${date} ${time}`;
      }

      return '';
    },
  },
  {
    title: 'Created',
    dataIndex: 'createdAt',
    key: 'createdAt',
    ellipsis: {
      showTitle: false,
    },
    render: value => moment(value)?.fromNow(),
  },
  {
    title: 'Campaign',
    dataIndex: 'campaign',
    key: 'campaign',
    ellipsis: {
      showTitle: false,
    },
    render: value => value?.name,
  },
  ...(isForTraffic
    ? [{}]
    : [
        {
          title: 'Status',
          key: 'status',
          ellipsis: true,
          render: (value: TUser) => status(value),
        },
      ]),
];
