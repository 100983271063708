/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable space-before-function-paren */
import React, { useState } from 'react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { TMetaData } from 'types';
import { TUser } from 'types/auth';
import { ExpandableConfig } from 'antd/es/table/interface';

type DataType = TUser;

interface Props<T> {
  meta?: TMetaData | undefined;
  columns: ColumnsType<T>;
  data: T[] | undefined;
  hideSizeChanger?: boolean;
  setPerPage?: (v: number) => void;
  expandedProps?: ExpandableConfig<T>;
  summary?: any;
}

export const ExpandedTable = <T extends DataType>({
  columns,
  data = [],
  hideSizeChanger,
  expandedProps,
  meta = {
    currentPage: 1,
    totalPages: 1,
    total: 0,
    perPage: 10,
  },
  summary,
}: Props<T>) => {
  const [currentPage, setCurrentPage] = useState(1);

  return (
    <Table
      rowKey={record => record.id}
      size={'small'}
      columns={columns}
      dataSource={data}
      scroll={{
        x: true,
      }}
      pagination={{
        total: meta?.total || meta?.count,
        current: currentPage,
        defaultCurrent: 1,
        pageSize: meta?.perPage || 100,
        showSizeChanger: hideSizeChanger ? false : true,
        hideOnSinglePage: true,
        showLessItems: true,
        onChange(page) {
          if (setCurrentPage) setCurrentPage(page);
        },
      }}
      expandable={expandedProps ? expandedProps : {}}
      summary={summary || (() => '')}
    />
  );
};
