import React from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { Partners } from '.';
import { PartnersHome } from './Home';
import { Edit } from './Edit';

interface Props {
  redirectRoute?: string;
}

export const PartnersRouter: React.FC<Props> = ({ redirectRoute }) => {
  return (
    <Routes>
      <Route
        element={
          <Partners>
            <Outlet />
          </Partners>
        }
      >
        <Route path={'/'} element={<PartnersHome />} />
        <Route path={'/edit/:id'} element={<Edit />} />
      </Route>
      <Route path="*" element={<Navigate to={redirectRoute || '..'} replace />} />
    </Routes>
  );
};
