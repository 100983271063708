type IOptions = {
    stringify: boolean
  }
  
const defaultOptions: IOptions = { stringify: true };
  
const local = <V = unknown>(key: string, options: Partial<IOptions> = defaultOptions) => {
  const { stringify } = { ...defaultOptions, ...options };
  
  return {
    get: (): V | undefined => {
      const value = localStorage.getItem(key);

      try {
        return value && stringify ? JSON.parse(value) : value;
      } catch (e) {
        return value as V;
      }
    },
    set: (value: V) => {
      const _value = value && stringify ? JSON.stringify(value) : value;

      localStorage.setItem(key, _value as string);
      window.dispatchEvent(new StorageEvent('storage', {
        newValue: value as string,
        key,
      }));
    },
    remove: () => {
      localStorage.removeItem(key);
      window.dispatchEvent(new StorageEvent('storage', { key }));
    },
    listen: (callback: (value: V | undefined) => void): (() => void) => {
      const listener = (event: StorageEvent) => {
        if (event.key === key || event.key === null) {
          try {
            const value = event.newValue ? JSON.parse(event.newValue) : event.newValue;

            callback(value);
          } catch (e) {
            callback(event.newValue as V);
          }
        }
      };

      window.addEventListener('storage', listener);
      
      return () => window.removeEventListener('storage', listener);
    },
  };
};
  
export default { local };
