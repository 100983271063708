/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable space-before-function-paren */
import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { TMetaData } from 'types';
import { TUser } from 'types/auth';
import { ColumnsType } from 'antd/es/table';
import { ExpandableConfig } from 'antd/es/table/interface';

import { TLanding } from 'types/landings';
import { TPartner } from 'types/partners';
import { IGetTraffic } from 'types/traffic';
import { Offer } from 'types/offer';
import { TPayment } from 'pages/Withdraws/utils/tableConfig';

type DataType = TUser | TLanding | IGetTraffic | TPartner | Offer | TPayment;

interface Props<T> {
  loading: boolean;
  meta?: TMetaData | undefined;
  columns: ColumnsType<T>;
  data: T[] | undefined;
  currentPage?: number;
  setCurrentPage?: (value: number) => void;
  hideSizeChanger?: boolean;
  setPerPage?: (v: number) => void;
  expandedProps?: ExpandableConfig<T>;
  summary?: any;
}

export const CustomTable = <T extends DataType>({
  loading,
  columns,
  data = [],
  meta = { currentPage: 1, totalPages: 1, total: 0, perPage: 10 },
  currentPage = 1,
  setCurrentPage,
  hideSizeChanger,
  expandedProps,
  summary,
}: Props<T>) => {
  const [nativeCurrentPage, setNativeCurrentPage] = useState(currentPage);

  useEffect(() => {
    if (!setCurrentPage) {
      const localCurrentPage = sessionStorage.getItem('currentPage');

      if (localCurrentPage) setNativeCurrentPage(Number(nativeCurrentPage));
    }
  }, []);

  useEffect(() => {
    if (!setCurrentPage) {
      sessionStorage.setItem('currentPage', String(nativeCurrentPage));

      return () => {
        sessionStorage.removeItem('currentPage');
      };
    }
  }, [nativeCurrentPage]);

  return (
    <Table
      loading={loading}
      rowKey={record => record.id}
      size={'small'}
      columns={columns}
      dataSource={data}
      scroll={{
        x: true,
      }}
      pagination={{
        total: meta?.total || meta?.count,
        current: setCurrentPage ? currentPage : nativeCurrentPage,
        defaultCurrent: 1,
        pageSize: meta?.perPage || 100,
        showSizeChanger: hideSizeChanger ? false : true,
        hideOnSinglePage: true,
        showLessItems: true,
        onChange(page) {
          if (setCurrentPage) {
            setCurrentPage(page);
          } else {
            setNativeCurrentPage(page);
          }
        },
      }}
      expandable={expandedProps ? expandedProps : {}}
      summary={summary || (() => '')}
    />
  );
};
