/* eslint-disable comma-dangle */
import React, { useState } from 'react';
import { CustomTable } from 'components/Table';
import { columns } from '../utils/tableConfig';
import { Form, Select } from 'antd';

import styles from './styles.module.scss';
import { useGetPaymentsQuery } from 'store/services/payments';

const sizes = [
  {
    value: 5,
    label: 5,
  },
  {
    value: 10,
    label: 10,
  },
  {
    value: 15,
    label: 15,
  },
  {
    value: 20,
    label: 20,
  },
  {
    value: 50,
    label: 50,
  },
  {
    value: 100,
    label: 100,
  },
];

export const RevenueHome = () => {
  const [perPage, setPerPage] = useState<number>(15);
  const [fields] = Form.useForm<{ perPage: string }>();
  const [currentPage, setCurrentPage] = useState<number>(1);

  const { data, isLoading, refetch } = useGetPaymentsQuery({
    page: currentPage,
    perPage,
    revenue: true,
  });

  return (
    <>
      <Form name="user-filters" form={fields}>
        <Form.Item name={'perPageSelect'} className={styles.paginationContainer}>
          <Select options={sizes} defaultValue={15} style={{ width: '70px' }} onChange={value => setPerPage(value)} />
        </Form.Item>
      </Form>

      <CustomTable
        loading={isLoading}
        columns={columns(refetch)}
        data={data}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        hideSizeChanger
      />
    </>
  );
};
