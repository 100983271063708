import React from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { Offers } from '.';
import { Add } from './Add';
import { Edit } from './Edit';
import { Home } from './Home';

type Props = {
  redirectRoute?: string;
};

export const OfferRouter: React.FC<Props> = ({ redirectRoute }) => {
  return (
    <Routes>
      <Route
        element={
          <Offers>
            <Outlet />
          </Offers>
        }
      >
        <Route
          path={'/'}
          element={
            <Home>
              <Outlet />
            </Home>
          }
        >
          <Route path={'/add'} element={<Add />} />
          <Route path={'/edit/:id'} element={<Edit />} />
        </Route>
      </Route>
      <Route path="*" element={<Navigate to={redirectRoute || '..'} replace />} />
    </Routes>
  );
};
