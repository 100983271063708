/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { AddPromoModal } from 'components/Modals/AddPromoModal';

export const Edit = () => {
  const navigate = useNavigate();

  const closeHandler = (onOk: boolean) => {
    navigate('..');

    if (onOk) {
      navigate(0);
    }
  };

  return (
    <>
      <Outlet />
      <AddPromoModal open={true} closeHandler={closeHandler} />
    </>
  );
};
