import React, { useState } from 'react';
import { Layout, Row, theme } from 'antd';
import { Navigation } from 'components/Navigation';
import { LogoType } from 'assets/svg';

const { Sider } = Layout;

type Props = {
  collapsed: boolean,
}

export const SideMenu: React.FC<Props> = ({
  collapsed,
}) => {
  const [ isFullScreen, setIsFullScreen ] = useState<boolean>(false);
  const { token: { colorPrimary } } = theme.useToken();

  return (
    <Sider
      theme='light'
      trigger={null}
      collapsible 
      collapsed={collapsed}
      collapsedWidth={isFullScreen ? '0' : '90px'}
      width={isFullScreen ? '100%' : '200px'}
      breakpoint="sm"
      onBreakpoint={setIsFullScreen}
      className={isFullScreen ? 'overflow-a h-fluid w-fluid top left bottom z-12' : ''}
      style={isFullScreen ? { position: 'fixed' } : { position: 'relative' }}
    >
      <Row
        justify={'center'}
        align={'middle'}
        className={'ma-5'}
      >
        <LogoType fill={{ primary: colorPrimary }}/> 
      </Row>
      <Navigation />
    </Sider>
  );
};
