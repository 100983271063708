/* eslint-disable function-paren-newline */
/* eslint-disable comma-dangle */
import { TGetBannedUsersParams, TActionUserParams, TBanUserParams, TSearchUserParams } from 'types/users';
import { API_ROUTES, BASE_URL } from 'api/routes';
import { IUserTicket, TMeta } from 'types';
import { $api } from 'api';

export const getUsers = async (params: TMeta) => {
  const { data } = await $api.get(`${BASE_URL}/${API_ROUTES.USERS}`, {
    params,
  });

  return data;
};

export const getBannedUsers = async (params: TGetBannedUsersParams) => {
  const { data } = await $api.post(`${BASE_URL}/${API_ROUTES.BANNED_USERS}`, params);

  return data;
};

export const getBannedUsersCount = async () => {
  const { data } = await $api.get(`${BASE_URL}/${API_ROUTES.BANNED_USERS_COUNT}`);

  return data;
};

export const getUsersCount = async () => {
  const { data } = await $api.get(`${BASE_URL}/${API_ROUTES.USERS_COUNT}`);

  return data;
};

export const getTicketsQuantity = async (userId: string) => {
  const res = await $api.get<IUserTicket>(`${BASE_URL}/ticket/by-admin/${userId}`);

  return res.data.quantity;
};

export const deleteUser = async (params: TActionUserParams) => {
  const { data } = await $api.delete(`${BASE_URL}/${API_ROUTES.USERS}/${params.userId}`);

  return data;
};

export const remove2fa = async (params: TActionUserParams) => {
  const { data } = await $api.delete(`${BASE_URL}/${API_ROUTES.REMOVE2FA}/${params.userId}`);

  return data;
};

export const banUser = async (params: TBanUserParams) => {
  const { data } = await $api.post(`${BASE_URL}/${API_ROUTES.BAN_USER}/${params.userId}`, {
    ban_message: params.banMessage,
    deleted_till: params.deletedTill,
  });

  return data;
};

export const getBan = async (params: { banId: string }) => {
  const { data } = await $api.get(`${BASE_URL}/${API_ROUTES.GET_BAN}/${params.banId}`);

  return data;
};

export const editBan = async (params: { banId: string }, payload: { banMessage: string; deletedTill: Date }) => {
  const { data } = await $api.patch(`${BASE_URL}/${API_ROUTES.BAN_USER}/${params.banId}`, payload);

  return data;
};

export const unbanUser = async (params: TActionUserParams) => {
  const { data } = await $api.delete(`${BASE_URL}/${API_ROUTES.UNBAN_USER}/${params.userId}`);

  return data;
};

export const searchUser = async (params: TSearchUserParams) => {
  const { data } = await $api.post(`${BASE_URL}/${API_ROUTES.SEARCH_USER}`, params);

  return data;
};

export const updateUserByAdmin = async (userId: string, payload: TSearchUserParams) => {
  const { data } = await $api.post(`${BASE_URL}/${API_ROUTES.UPDATE_BY_ADMIN}/${userId}`, payload);

  return data;
};

export const updateUserBalanceByAdmin = async (balanceId: string, payload: TSearchUserParams) => {
  const { data } = await $api.post(`${BASE_URL}/${API_ROUTES.UPDATE_BALANCE_BY_ADMIN}/${balanceId}`, payload);

  return data;
};

export const createUserBalanceByAdmin = async (blockchain: string, payload: TSearchUserParams) => {
  const { data } = await $api.post(`${BASE_URL}/${API_ROUTES.CREATE_BALANCE_BY_ADMIN}/${blockchain}`, payload);

  return data;
};

export const userStatistic = async (id: string) => {
  const { data } = await $api.post(`${BASE_URL}/${API_ROUTES.STATISTIC_USER}`, {
    userId: id,
  });

  return data;
};
