/* eslint-disable function-paren-newline */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-bracket-spacing */
import React, { useState } from 'react';
import { Button, Form, message, Select } from 'antd';
import { setPartnerToCommand } from 'api/traffic';
import { useGetCommandsWithoutPartnerQuery } from 'store/services/payments';
import { useParams } from 'react-router-dom';

type Props = {
  closeHandler: (onOk: boolean) => void;
};

export const SetPartner: React.FC<Props> = ({ closeHandler }) => {
  const { id } = useParams();
  const [fields] = Form.useForm();
  const messageKey = 'messageKey-1';
  const [_, setIsLoading] = useState<boolean>(false);
  const { data: commands } = useGetCommandsWithoutPartnerQuery({});

  const onFinish = async () => {
    try {
      setIsLoading(true);
      message.open({
        key: messageKey,
        type: 'loading',
        content: 'Sending data...',
      });

      await setPartnerToCommand({ ...fields.getFieldsValue(), partnerId: id });

      setIsLoading(false);
      closeHandler(true);
    } catch (e) {
      message.open({
        key: messageKey,
        type: 'error',
        content: 'Error',
        duration: 2,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onFinishChecker = async () => {
    message.error('Error in form');
  };

  return (
    <Form form={fields} layout="vertical" onFinishFailed={onFinishChecker}>
      <Form.Item name="commandId" label="Commands">
        <Select
          options={commands?.map(({ id, name }: any) => ({ value: id, label: name })) || []}
          style={{ width: '350px' }}
          placeholder="Select Command"
        />
      </Form.Item>

      <Button onClick={() => onFinish()}>Set Partner</Button>
    </Form>
  );
};
