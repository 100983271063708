import React from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { Revenue } from '.';
import { RevenueHome } from './Home/index';

interface Props {
  redirectRoute?: string;
}

export const RevenueRouter: React.FC<Props> = ({ redirectRoute }) => {
  return (
    <Routes>
      <Route
        element={
          <Revenue>
            <Outlet />
          </Revenue>
        }
      >
        <Route path={'/'} element={<RevenueHome />} />
      </Route>
      <Route path="*" element={<Navigate to={redirectRoute || '..'} replace />} />
    </Routes>
  );
};
