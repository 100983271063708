import { TSignIn } from 'types/auth';
import { API_ROUTES, BASE_URL } from 'api/routes';
import { $api } from 'api';
import axios from 'axios';

export const signIn = async (params: TSignIn) => {
  const ipChecker = await axios.get('https://lumtest.com/echo.json');

  const { data } = await $api.post(
    `${BASE_URL}/${API_ROUTES.SIGN_IN}`,
    {
      ...params,
      ip: ipChecker.data.ip,
    },
  );

  return data;
};
