import React, { useState } from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { MainLayout, LoginLayout } from 'components/Layouts';
import { Logout, Login } from 'pages';
import { paths } from 'pages/paths';
import local from 'hooks/local';
import { TrafficRouter } from './Traffic/router';

import { PartnersRouter } from './BinobiPartners/router';
import { OfferRouter } from './Offers/router';
import { WithdrawsRouter } from './Withdraws/router';
import { RevenueRouter } from './Revenue/router';

const MyRouter = () => {
  const storage = local;
  const storageToken = storage.local<string>('token', { stringify: false });
  const [token, setToken] = useState<string | undefined>(storageToken.get());

  storageToken.listen(value => setToken(value));

  return (
    <Routes>
      {token ? (
        <Route
          element={
            <MainLayout>
              <Outlet />
            </MainLayout>
          }
        >
          <Route path={`${paths.TRAFFIC}/*`} element={<TrafficRouter />} />
          <Route path={`${paths.OFFERS}/*`} element={<OfferRouter />} />
          <Route path={`${paths.BINOBI_PARTNERS}/*`} element={<PartnersRouter />} />
          <Route path={`${paths.WITHDRAWS}/*`} element={<WithdrawsRouter />} />
          <Route path={`${paths.REVENUES}/*`} element={<RevenueRouter />} />
          <Route path={paths.LOGOUT} element={<Logout />} />

          <Route path="*" element={<Navigate to={paths.BINOBI_PARTNERS} replace />} />
        </Route>
      ) : (
        <>
          <Route
            element={
              <LoginLayout>
                <Outlet />
              </LoginLayout>
            }
          >
            <Route path={'/'} element={<Login />} />
          </Route>

          <Route path="*" element={<Navigate to="/" replace />} />
        </>
      )}
    </Routes>
  );
};

export default MyRouter;
