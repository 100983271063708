import React from 'react';
import { Button, Space } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { removeOffer } from 'api/traffic';

type Props = {
  id: string;
};

export const Actions: React.FC<Props> = ({ id }) => {
  const navigate = useNavigate();

  const onDelete = async () => {
    await removeOffer(id);

    navigate(0);
  };

  return (
    <Space>
      <Link to={`edit/${id}`}>
        <Button type="primary"> Edit</Button>
      </Link>
      <Button type="primary" danger onClick={() => onDelete()}>
        Delete
      </Button>
    </Space>
  );
};
