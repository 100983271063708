import React from 'react';
import { Link } from 'react-router-dom';
import {
  RollbackOutlined,
  HeatMapOutlined,
  PartitionOutlined,
  UserSwitchOutlined,
  DollarOutlined,
  EuroCircleOutlined,
} from '@ant-design/icons';
import { paths } from 'pages/paths';
import { ItemType } from "antd/es/menu/interface";

export const links: Array<ItemType> = [
  {
    icon: <UserSwitchOutlined />,
    label: <Link to={paths.BINOBI_PARTNERS}>Binobi Partners</Link>,
    key: paths.BINOBI_PARTNERS,
  },
  {
    icon: <PartitionOutlined />,
    label: <Link to={paths.TRAFFIC}>Traffic</Link>,
    key: paths.TRAFFIC,
  },
  // {
  //   icon: <FileImageOutlined />,
  //   label: <Link to={paths.LANDINGS}>Landings</Link>,
  //   key: paths.LANDINGS,
  // },
  {
    icon: <HeatMapOutlined />,
    label: <Link to={paths.OFFERS}>Offers</Link>,
    key: paths.OFFERS,
  },

  {
    icon: <DollarOutlined />,
    label: <Link to={paths.WITHDRAWS}>Withdraws</Link>,
    key: paths.WITHDRAWS,
  },

  {
    icon: <EuroCircleOutlined />,
    label: <Link to={paths.REVENUES}>Revenues</Link>,
    key: paths.REVENUES,
  },
  {
    icon: '',
    label: '',
    key: '',
  },
  {
    icon: <RollbackOutlined />,
    label: <Link to={paths.LOGOUT}>Logout</Link>,
    key: paths.LOGOUT,
  },
];
