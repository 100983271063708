import React from 'react';
import ReactDOM from 'react-dom/client';
import 'index.scss';
import { BrowserRouter } from 'react-router-dom';
import App from 'App';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <BrowserRouter>
    <App />

    <ToastContainer />
  </BrowserRouter>,
);
