import React, { useState } from 'react';
import { Button, Space } from 'antd';
import { PaymentStatus } from './tableConfig';
import { useChangeStatusMutation } from 'store/services/payments';
import { TextAreaModal } from 'components/Modals/TextAreaModal';

type Props = {
  id: string;
  refetch: () => void;
  status: PaymentStatus;
};

export const Actions: React.FC<Props> = ({ id, refetch, status }) => {
  const [changeStatus] = useChangeStatusMutation();

  const [isOpen, setIsOpen] = useState(false);

  const onClick = async (status: PaymentStatus, comment?: string) => {
    await changeStatus({ status, paymentId: id, comment });

    refetch();
  };

  return (
    <Space>
      {status === PaymentStatus.InPayment && (
        <Button type="primary" style={{ backgroundColor: 'green' }} onClick={() => onClick(PaymentStatus.Paid)}>
          Paid
        </Button>
      )}

      {status === PaymentStatus.Processing && (
        <>
          <Button type="primary" style={{ backgroundColor: 'orange' }} onClick={() => onClick(PaymentStatus.InPayment)}>
            In-Payment
          </Button>

          <Button type="primary" danger onClick={() => setIsOpen(true)}>
            Decline
          </Button>
        </>
      )}

      <TextAreaModal
        closeHandler={v => setIsOpen(v)}
        callback={comment => onClick(PaymentStatus.Decline, comment)}
        open={isOpen}
      />
    </Space>
  );
};
