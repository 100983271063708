import React from 'react';
import { links } from './utils/links';
import { useLocation } from 'react-router-dom';
import { Menu } from 'antd';

export const Navigation: React.FC = () => {
  const location = useLocation();

  return <Menu mode="inline" defaultSelectedKeys={[location.pathname]} items={links} />;
};
