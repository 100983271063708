// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_paginationContainer__xNTjs {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;
}

.styles_usernameFilter__vkTL4 {
  max-width: 300px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Revenue/Home/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EAEA,aAAA;EACA,yBAAA;EACA,kBAAA;AAAF;;AAGA;EACE,gBAAA;AAAF","sourcesContent":[".paginationContainer {\n  width: 100%;\n\n  display: flex;\n  justify-content: flex-end;\n  margin-bottom: 5px;\n}\n\n.usernameFilter {\n  max-width: 300px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paginationContainer": `styles_paginationContainer__xNTjs`,
	"usernameFilter": `styles_usernameFilter__vkTL4`
};
export default ___CSS_LOADER_EXPORT___;
