import React, { useEffect, useState } from 'react';
import { CustomTable } from 'components/Table';
import { columns } from '../utils/tableConfig';
import { children } from 'types';
import { Form, Select } from 'antd';
import styles from '../styles.module.scss';
import { getOffers } from 'api/traffic';

interface Props {
  children: children;
}

const items = [
  {
    value: 15,
    label: 15,
  },
  {
    value: 20,
    label: 20,
  },
  {
    value: 50,
    label: 50,
  },
];

export const Home: React.FC<Props> = ({ children }) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(15);

  const [data, setData] = useState();
  const [loading, setIsLoading] = useState(false);

  const handlePromos = async () => {
    setIsLoading(true);
    const landings = await getOffers({ perPage });

    setIsLoading(false);
    setData(landings);
  };

  useEffect(() => {
    handlePromos();
  }, []);

  return (
    <>
      <Form.Item className={styles.paginationContainer}>
        <Select options={items} defaultValue={15} style={{ width: '70px' }} onChange={value => setPerPage(value)} />
      </Form.Item>

      <CustomTable
        loading={loading}
        columns={columns}
        data={data}
        meta={data}
        hideSizeChanger
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
      {children}
    </>
  );
};
