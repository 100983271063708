export const API_ROUTES = {
  SIGN_IN: 'auth/login/admin',
  LOGOUT: 'auth/logout',
  USERS: 'users',
  PARTNERS: 'partners',
  USERS_COUNT: 'users/count',
  ARTICLES: 'articles',
  BANS: 'bans',
  PROMOCODES: 'promocodes',
  BONUSES: 'bonuses/v2',
  DAILY_QUEST: 'daily-quest',
  CATEGORIES: 'category',
  GAMES: 'games',
  TRAFFIC_COMMAND: 'traffic/command',
  TRAFFIC_LANDINGS: 'traffic/landings',
  TRAFFIC_OFFER: 'traffic/offers',
  TRAFFIC_CAMPAIGN: 'traffic/campaign',
  TRAFFIC: 'traffic',
  BANNED_USERS: 'banned-users',
  BANNED_USERS_COUNT: 'banned-users/count',
  BAN_USER: 'bans',
  GET_BAN: 'bans/get-one',
  ROLE: 'users/role',
  CHANGE_DATA: 'users/change',
  SESSIONS: 'users/sessions',
  UNBAN_USER: 'users/unban',
  USER_CHANGES: 'user-change',
  SEND_TIPS: 'send',
  TICKETS_TX: 'tickets/transactions/admin',
  SUSPICIOUS_USERS: 'detector',
  SEARCH_USER: 'users/search',
  UPDATE_BY_ADMIN: 'users/update-by-admin',
  CREATE_BALANCE_BY_ADMIN: 'balance/create-user-balance-by-admin',
  UPDATE_BALANCE_BY_ADMIN: 'balance/change-user-balance-by-admin',
  STATISTIC_USER: 'users/statistic',
  VERIFICATIONS: 'verifications',
  BANNED_COUNTRY: 'banned-country',
  HISTORY: 'history',
  WITHDRAW: 'withdraw',
  AWS: 'aws',
  REFRESH: 'auth/refresh',
  STATISTIC: 'statistic',
  REMOVE2FA: 'auth/2fa/remove',

  CHANGE_PAYMENT_STATUS: 'partners/change-payment-status',
  GET_ALL_PAYMENTS: 'partners/get-all-payments',

  GET_COMMAND_WITHOUT_PARTNER: 'traffic/command/without-partner',
  SET_PARTNER_TO_COMMAND: 'traffic/command/set-partner-to-command',
};

export const BASE_URL = process.env.REACT_APP_API_ENDPOINT;
