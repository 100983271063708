export enum OfferDomains {
  CASINO = 'casino',
}

export enum OfferPaymentModel {
  REVSHARE = 'revshare',
  CPA = 'cpa',
}
export interface Offer {
  id: string;

  domain: OfferDomains;

  paymentModel: OfferPaymentModel;

  goal: string;

  geo: string;
}
