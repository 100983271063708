import { BASE_URL } from 'api/routes';

import { $api } from 'api';

export const declinePartner = async (partnerId: string) => {
  const { data } = await $api.get(`${BASE_URL}/partners/profile/decline/${partnerId}`);

  return data;
};

export const confirmPartner = async (partnerId: string) => {
  const { data } = await $api.get(`${BASE_URL}/partners/profile/confirm/${partnerId}`);

  return data;
};
