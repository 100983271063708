import React from 'react';
import { Modal } from 'antd';
import { OffersEdit } from 'components/Forms/Offers';

interface Props {
  open: boolean;
  id?: string;
  closeHandler: (onOk: boolean) => void;
}

export const AddPromoModal: React.FC<Props> = ({ open, closeHandler }) => {
  const onOk = () => closeHandler(true);
  const onCancel = () => closeHandler(false);

  return (
    <>
      <Modal title={'Promo modal'} open={open} onOk={onOk} onCancel={onCancel} footer={null}>
        <OffersEdit closeHandler={closeHandler} />
      </Modal>
    </>
  );
};
