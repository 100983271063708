/* eslint-disable comma-dangle */
import React, { useEffect, useState } from 'react';
import { CustomTable } from 'components/Table';
import { columns } from '../utils/tableConfig';
import { usersApi } from 'store/services';
import { useDebouncedState } from '@mantine/hooks';
import { Form, Input, Select } from 'antd';

import styles from './styles.module.scss';

const sizes = [
  {
    value: 5,
    label: 5,
  },
  {
    value: 10,
    label: 10,
  },
  {
    value: 15,
    label: 15,
  },
  {
    value: 20,
    label: 20,
  },
  {
    value: 50,
    label: 50,
  },
  {
    value: 100,
    label: 100,
  },
];

export const PartnersHome = () => {
  const [fields] = Form.useForm<{ username: string }>();
  const [perPage, setPerPage] = useState<number>(15);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filterEmail, setFilterEmail] = useDebouncedState<string | null>(null, 500);

  const { data, isLoading, refetch } = usersApi.useGetAllPartnersQuery({
    page: currentPage,
    perPage,
    order: 'DESC',
    filterBy: 'createdAt',

    where: JSON.stringify({
      ...(filterEmail && { email: filterEmail }),
    }),
  });

  useEffect(() => {
    const localCurrentPage = sessionStorage.getItem('currentPage');
    const localFilterName = sessionStorage.getItem('filterEmail');
    const localPerPage = sessionStorage.getItem('perPage');

    if (localCurrentPage) setCurrentPage(Number(localCurrentPage));
    if (localPerPage) {
      fields.setFieldValue('perPageSelect', localPerPage);
      setPerPage(Number(localPerPage));
    }
    if (localFilterName) {
      fields.setFieldValue('username', localFilterName);
      setFilterEmail(localFilterName);
    }
  }, []);

  useEffect(() => {
    sessionStorage.setItem('currentPage', String(currentPage));
    sessionStorage.setItem('perPage', String(perPage));

    if (filterEmail) sessionStorage.setItem('filterEmail', filterEmail);

    return () => {
      sessionStorage.removeItem('currentPage');
      sessionStorage.removeItem('perPage');
      sessionStorage.removeItem('filterEmail');
    };
  }, [currentPage, perPage, filterEmail]);

  return (
    <>
      <Form name="partners-filters" form={fields}>
        <Form.Item label="Email" name="email" className={styles.usernameFilter}>
          <Input placeholder="Enter email" name="email" onChange={event => setFilterEmail(event.target.value)} />
        </Form.Item>
        <Form.Item name={'perPageSelect'} className={styles.paginationContainer}>
          <Select options={sizes} defaultValue={15} style={{ width: '70px' }} onChange={value => setPerPage(value)} />
        </Form.Item>
      </Form>
      <CustomTable
        loading={isLoading}
        columns={columns(refetch)}
        data={data?.data}
        meta={data?.meta}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        hideSizeChanger
      />
    </>
  );
};
