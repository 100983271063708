/* eslint-disable function-paren-newline */
/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Tag } from 'antd';
import moment from 'moment';
import { ColumnsType } from 'antd/es/table';

import { BinobiPartnersActions } from './Actions';
import { TPartner } from 'types/partners';
import { Link } from 'react-router-dom';
import { copy } from 'utils/copy';

const status = (isPartnerConfirmed: boolean, declined?: boolean) => (
  <>
    {isPartnerConfirmed ? (
      <Tag color="green">Confirmed</Tag>
    ) : !declined ? (
      <Tag color="yellow">Under Review</Tag>
    ) : (
      <Tag color="red">Declined</Tag>
    )}
  </>
);

export const columns = (refetch: () => void): ColumnsType<TPartner> => [
  {
    title: '#',
    dataIndex: 'number',
    key: 'number',
    align: 'center',
    fixed: 'left',
    ellipsis: true,
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    ellipsis: true,
    render: (_, record) => record?.user?.email,
  },
  {
    title: 'Messenger',
    dataIndex: 'messengerType',
    key: 'messengerType',
    ellipsis: true,
  },
  {
    title: 'Messenger Login',
    dataIndex: 'messenger',
    key: 'messenger',
    ellipsis: true,
  },

  {
    title: 'Payment Method',
    dataIndex: 'paymentsCredentials',
    key: 'paymentsCredentials',
    ellipsis: true,
    render: v => {
      const payment = v.find(({ active }: { active: boolean }) => active === true);

      return (
        <div style={{ cursor: 'pointer' }} onClick={() => copy(payment?.paymentMethod)}>
          {payment?.paymentMethod}
        </div>
      );
    },
  },
  {
    title: 'Payment Address',
    dataIndex: 'paymentsCredentials',
    key: 'paymentsCredentials',
    ellipsis: true,
    render: v => {
      const payment = v.find(({ active }: { active: boolean }) => active === true);

      return (
        <div style={{ cursor: 'pointer' }} onClick={() => copy(payment?.paymentAddress)}>
          {payment?.paymentAddress}
        </div>
      );
    },
  },

  {
    title: 'Status',
    dataIndex: 'isPartnerConfirmed',
    key: 'isPartnerConfirmed',
    ellipsis: true,
    render: (_, record) => {
      const user = record?.user;

      if (user) return status(user.isPartnerConfirmed || false, user.declined);
    },
  },

  {
    title: 'Commands',
    dataIndex: 'commands',
    key: 'commands',
    ellipsis: {
      showTitle: false,
    },
    render: (value, record) =>
      record?.user?.isPartnerConfirmed ? (
        <Link to={`/traffic?commands=${value?.map(({ name }: { name: string }) => name).toString()}`}>
          {value
            ?.map(({ name }: { name: string }) => name)
            .toString()
            .replaceAll(',', ', ')}
        </Link>
      ) : (
        'Empty'
      ),
  },
  {
    title: 'Created',
    dataIndex: 'createdAt',
    key: 'createdAt',
    ellipsis: {
      showTitle: false,
    },
    render: value => moment(value)?.fromNow(),
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    align: 'center',
    render: (_, record) => BinobiPartnersActions(record?.user, refetch),
  },
];
