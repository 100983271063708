/* eslint-disable @typescript-eslint/no-explicit-any */

import React from 'react';
import { Table, Typography } from 'antd';

import styles from '../Home/styles.module.scss';

const calculateAll = (arr: any[]) => {
  let allClicks = 0;
  let allRegistrations = 0;
  let allFdp = 0;
  let allRdp = 0;
  let allDepositsSum = 0;
  let allBetSum = 0;
  let allWinSum = 0;
  let allLoseSum = 0;

  arr.forEach(({ clicks, registrations, betSum, winSum, depositsSum, fdp, loseSum, rdp }: any) => {
    allClicks = allClicks + clicks;
    allRegistrations = allRegistrations + registrations;
    allFdp = allFdp + fdp;
    allRdp = allRdp + rdp;
    allDepositsSum = allDepositsSum + depositsSum;
    allBetSum = allBetSum + betSum;
    allWinSum = allWinSum + winSum;
    allLoseSum = allLoseSum + loseSum;
  });

  return {
    allFdp,
    allRdp,
    allClicks,
    allBetSum,
    allWinSum,
    allLoseSum,
    allDepositsSum,
    allRegistrations,
  };
};

export const summary = (arr: any[]) => {
  const calculated = calculateAll(arr);

  if (!calculated) return null;
  const { allClicks, allRegistrations, allFdp, allRdp, allDepositsSum, allBetSum, allWinSum, allLoseSum } = calculated;

  return (
    <Table.Summary.Row className={styles.bold_border}>
      <Table.Summary.Cell index={0}></Table.Summary.Cell>
      <Table.Summary.Cell index={1}>Total</Table.Summary.Cell>

      <Table.Summary.Cell index={2}> - </Table.Summary.Cell>

      <Table.Summary.Cell index={3}>
        <Typography.Text>{allClicks}</Typography.Text>
      </Table.Summary.Cell>

      <Table.Summary.Cell index={4}>
        <Typography.Text>{allRegistrations}</Typography.Text>
      </Table.Summary.Cell>

      <Table.Summary.Cell index={5}>
        <Typography.Text>{allFdp}</Typography.Text>
      </Table.Summary.Cell>

      <Table.Summary.Cell index={6}>
        <Typography.Text>{allRdp}</Typography.Text>
      </Table.Summary.Cell>

      <Table.Summary.Cell index={7}>
        <Typography.Text>{allDepositsSum}</Typography.Text>
      </Table.Summary.Cell>

      <Table.Summary.Cell index={8}>
        <Typography.Text>{allBetSum}</Typography.Text>
      </Table.Summary.Cell>

      <Table.Summary.Cell index={9}>
        <Typography.Text>{allWinSum}</Typography.Text>
      </Table.Summary.Cell>

      <Table.Summary.Cell index={10}>
        <Typography.Text>{allLoseSum}</Typography.Text>
      </Table.Summary.Cell>

      <Table.Summary.Cell index={11}>
        <Typography.Text></Typography.Text>
      </Table.Summary.Cell>
    </Table.Summary.Row>
  );
};

export const expandedSummary = (arr: any) => {
  const calculated = calculateAll(arr);

  if (!calculated) return null;
  const { allClicks, allRegistrations, allFdp, allRdp, allDepositsSum, allBetSum, allWinSum, allLoseSum } = calculated;

  return (
    <Table.Summary.Row className={styles.yellow_bold_border}>
      <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>

      <Table.Summary.Cell index={1}> - </Table.Summary.Cell>

      <Table.Summary.Cell index={2}>
        <Typography.Text>{allClicks}</Typography.Text>
      </Table.Summary.Cell>

      <Table.Summary.Cell index={3}>
        <Typography.Text>{allRegistrations}</Typography.Text>
      </Table.Summary.Cell>

      <Table.Summary.Cell index={4}>
        <Typography.Text>{allFdp}</Typography.Text>
      </Table.Summary.Cell>

      <Table.Summary.Cell index={5}>
        <Typography.Text>{allRdp}</Typography.Text>
      </Table.Summary.Cell>

      <Table.Summary.Cell index={6}>
        <Typography.Text>{allDepositsSum}</Typography.Text>
      </Table.Summary.Cell>

      <Table.Summary.Cell index={7}>
        <Typography.Text>{allBetSum}</Typography.Text>
      </Table.Summary.Cell>

      <Table.Summary.Cell index={8}>
        <Typography.Text>{allWinSum}</Typography.Text>
      </Table.Summary.Cell>

      <Table.Summary.Cell index={9}>
        <Typography.Text>{allLoseSum}</Typography.Text>
      </Table.Summary.Cell>

      <Table.Summary.Cell index={10}>
        <Typography.Text></Typography.Text>
      </Table.Summary.Cell>
    </Table.Summary.Row>
  );
};
