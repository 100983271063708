import React from 'react';
import { Modal } from 'antd';
import { SetPartner } from 'components/Forms/SetPartner';

interface Props {
  open: boolean;
  id?: string;
  closeHandler: (onOk: boolean) => void;
}

export const SetPartnerModal: React.FC<Props> = ({ open, closeHandler }) => {
  const onOk = () => closeHandler(true);
  const onCancel = () => closeHandler(false);

  return (
    <>
      <Modal title={'Set partner modal'} open={open} onOk={onOk} onCancel={onCancel} footer={null}>
        <SetPartner closeHandler={closeHandler} />
      </Modal>
    </>
  );
};
