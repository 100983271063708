import React from 'react';
import { Header } from 'components/Layouts';
import { children } from 'types';

interface Props {
  children: children;
}

export const Revenue: React.FC<Props> = ({ children }) => {
  return (
    <>
      <Header label="Revenues" />
      {children}
    </>
  );
};
