import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { Header } from 'components/Layouts';
import { children } from 'types';

interface Props {
  children: children;
}

export const Offers: React.FC<Props> = ({ children }) => {
  return (
    <>
      <Header label="Offers">
        <Button>
          <Link to={'add'}>Add new offer</Link>
        </Button>
      </Header>
      {children}
    </>
  );
};
