export const paths = {
  HOME: '/',
  USERS: '/users',
  LANDINGS: '/landings',
  OFFERS: '/offers',
  TRAFFIC: '/traffic',
  PROMOCODES: '/promocodes',
  BINOBI_PARTNERS: '/binobi-partners',
  WITHDRAWS: '/withdraws',
  REVENUES: '/revenues',
  LOGOUT: '/logout',
};
