/* eslint-disable function-paren-newline */
/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Tag } from 'antd';
import moment from 'moment';
import { ColumnsType } from 'antd/es/table';

export enum PaymentStatus {
  Processing = 'processing',
  InPayment = 'in-payment',
  Paid = 'paid',
  Decline = 'declined',
}

export type TPayment = {
  id: string;
  status: PaymentStatus;
  amount: string;
  leadDeposit: string;
  partner: {
    user: {
      email: string;
    };
  };
  lead: {
    username: string;
  };
};

const status = (value: PaymentStatus) => (
  <>
    {value === PaymentStatus.Processing && <Tag color={'yellow'}>{value}</Tag>}
    {value === PaymentStatus.InPayment && <Tag color={'orange'}>{value}</Tag>}
    {value === PaymentStatus.Decline && <Tag color={'red'}>{value}</Tag>}
    {value === PaymentStatus.Paid && <Tag color={'green'}>{value}</Tag>}
  </>
);

export const columns = (refetch: () => void): ColumnsType<TPayment> => [
  {
    title: 'Amount cpas',
    dataIndex: 'amount',
    key: 'amount',
    ellipsis: true,
  },
  // {
  //   title: 'Status',
  //   key: 'status',
  //   dataIndex: 'status',
  //   ellipsis: true,
  //   render: value => status(value),
  // },
  {
    title: 'Partner',
    dataIndex: 'partner',
    key: 'partner',
    fixed: 'left',
    ellipsis: true,
    render: value => value?.user?.email,
  },
  {
    title: 'Lead',
    dataIndex: 'lead',
    key: 'lead',
    ellipsis: true,
    render: value => value?.username,
  },

  {
    title: 'LeadDeposit',
    dataIndex: 'leadDeposit',
    key: 'leadDeposit',
    ellipsis: true,
  },

  {
    title: 'Created',
    dataIndex: 'createdAt',
    key: 'createdAt',
    ellipsis: {
      showTitle: false,
    },
    render: value => moment(value)?.fromNow(),
  },
];
