/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable comma-dangle */
import { $api } from 'api';
import { ICreateCampaign, ICreateCommand } from 'types/traffic';
import { API_ROUTES, BASE_URL } from 'api/routes';
import { TMeta } from 'types';

export const createCommand = async (payload: ICreateCommand) => {
  const { data } = await $api.post(`${BASE_URL}/${API_ROUTES.TRAFFIC_COMMAND}`, payload);

  return data;
};

export const getLandingHtml = async (url: string) => {
  const { data } = await $api.get(url);

  return data;
};

export const getCommands = async () => {
  const { data } = await $api.get(`${BASE_URL}/${API_ROUTES.TRAFFIC_COMMAND}`);

  return data;
};

export const getLandings = async (params?: TMeta) => {
  const { data } = await $api.get(`${BASE_URL}/${API_ROUTES.TRAFFIC_LANDINGS}`, { params });

  return data;
};

export const getLanding = async (id?: string) => {
  const { data } = await $api.get(`${BASE_URL}/${API_ROUTES.TRAFFIC_LANDINGS}/get-one`, { params: { id } });

  return data;
};

export const updateLanding = async (payload: any) => {
  const { data } = await $api.post(`${BASE_URL}/${API_ROUTES.TRAFFIC_LANDINGS}/edit`, payload);

  return data;
};

export const removeLanding = async (id?: string) => {
  const { data } = await $api.get(`${BASE_URL}/${API_ROUTES.TRAFFIC_LANDINGS}/delete`, { params: { id } });

  return data;
};

export const createLanding = async (payload: any) => {
  const { data } = await $api.post(`${BASE_URL}/${API_ROUTES.TRAFFIC_LANDINGS}/create`, payload);

  return data;
};

export const createCampaign = async (payload: ICreateCampaign) => {
  const { data } = await $api.post(`${BASE_URL}/${API_ROUTES.TRAFFIC_CAMPAIGN}`, payload);

  return data;
};

export const getTraffic = async (params?: TMeta) => {
  const { data } = await $api.get(`${BASE_URL}/${API_ROUTES.TRAFFIC}`, {
    params,
  });

  return data;
};

export const getCampaign = async (id: string | null) => {
  const { data } = await $api.get(`${BASE_URL}/${API_ROUTES.TRAFFIC_CAMPAIGN}`, {
    params: { ...(id && { id }) },
  });

  return data;
};

export const getCommand = async (id: string | null) => {
  const { data } = await $api.get(`${BASE_URL}/${API_ROUTES.TRAFFIC_COMMAND}/one`, {
    params: { ...(id && { id }) },
  });

  return data;
};

export const editCampaign = async (payload: any) => {
  const { data } = await $api.post(`${BASE_URL}/${API_ROUTES.TRAFFIC_CAMPAIGN}/edit`, payload);

  return data;
};

export const editCommand = async (payload: any) => {
  const { data } = await $api.post(`${BASE_URL}/${API_ROUTES.TRAFFIC_COMMAND}/edit`, payload);

  return data;
};

export const setPartnerToCommand = async (payload: any) => {
  const { data } = await $api.post(`${BASE_URL}/${API_ROUTES.SET_PARTNER_TO_COMMAND}`, payload);

  return data;
};

export const deleteCampaign = async (id: string) => {
  const { data } = await $api.delete(`${BASE_URL}/${API_ROUTES.TRAFFIC_CAMPAIGN}`, {
    params: { id },
  });

  return data;
};

export const changeStatus = async (id: string, status?: string) => {
  const { data } = await $api.post(`${BASE_URL}/${API_ROUTES.TRAFFIC_CAMPAIGN}/status`, {
    id,
    status,
  });

  return data;
};

export const deleteCommand = async (id: string) => {
  const { data } = await $api.delete(`${BASE_URL}/${API_ROUTES.TRAFFIC_COMMAND}`, {
    params: { id },
  });

  return data;
};

// Promo

export const getOffers = async (params?: TMeta) => {
  const { data } = await $api.get(`${BASE_URL}/${API_ROUTES.TRAFFIC_OFFER}`, { params });

  return data;
};

export const getOffer = async (id?: string) => {
  const { data } = await $api.get(`${BASE_URL}/${API_ROUTES.TRAFFIC_OFFER}/get-one`, { params: { id } });

  return data;
};

export const updateOffer = async (payload: any) => {
  const { data } = await $api.post(`${BASE_URL}/${API_ROUTES.TRAFFIC_OFFER}/edit`, payload);

  return data;
};

export const removeOffer = async (id?: string) => {
  const { data } = await $api.get(`${BASE_URL}/${API_ROUTES.TRAFFIC_OFFER}/delete`, { params: { id } });

  return data;
};

export const createOffer = async (payload: any) => {
  const { data } = await $api.post(`${BASE_URL}/${API_ROUTES.TRAFFIC_OFFER}/create`, payload);

  return data;
};
