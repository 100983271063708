/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { SetPartnerModal } from 'components/Modals/SetPartnerModal';

export const Edit = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const closeHandler = (onOk: boolean) => {
    navigate('..');

    if (onOk) {
      navigate(0);
    }
  };

  return (
    <>
      <Outlet />
      <SetPartnerModal open={true} id={id} closeHandler={closeHandler} />
    </>
  );
};
